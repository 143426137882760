import React from "react";
import { Helmet } from "react-helmet";
import * as styles from "./style.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import {
  commissionMinimal,
  commissionPercent,
  LAPTOP_MOBILE_POINTER,
  walletLink,
  walletStoreLinkIos,
  walletStoreLinkMd,
} from "@tools/constants";
import { ButtonUI, NoGapUI, InfoTooltip, TypographyUI } from "@ui";
import { BackgroundPosterCt, ButtonOfScrollCt, Layout } from "@components";
import sCardImg from "@images/elektronniy-koshelek/service-card.png";
import sFreeImg from "@images/elektronniy-koshelek/service-free.png";
import sHistoryImg from "@images/elektronniy-koshelek/service-history.png";
import sWalletImg from "@images/elektronniy-koshelek/service-wallet.png";
import srcNumStep1Img from "@images/elektronniy-koshelek/num-1.svg";
import srcNumStep2Img from "@images/elektronniy-koshelek/num-2.svg";
import { useSendEvent } from "@tools/hooks";

const WalletFlPage = () => {
  const { clickDownloadApple, clickDownloadGoogle, tryWallet, openWallet } = useSendEvent();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Личный электронный кошелек в России 2024 | Открыть электронный кошелек онлайн
        </title>
        <meta
          name="description"
          content="В поиске личного электронного кошелька в России в 2024? Открывайте новый электронный кошелек вместе с нами! Удобный сервис для физических лиц"
        />
        <link
          rel="canonical"
          href="https://xn--80aapgyievp4gwb.xn--p1ai/elektronniy-koshelek"
        />
      </Helmet>
      <Layout>
        <div className={styles.containerTop}>
          <BackgroundPosterCt />
          <div className={styles.text}>
            <TypographyUI.H1
              swichPoint={LAPTOP_MOBILE_POINTER}
              className={styles.title}
            >Откройте электронный кошелек для <NoGapUI>физических лиц</NoGapUI></TypographyUI.H1>
            <TypographyUI.Body1Regular
              swichPoint={LAPTOP_MOBILE_POINTER}
              className={styles.description}
            >
              И принимайте деньги на любые цели от друзей и родственников
            </TypographyUI.Body1Regular>

            <a
              target="_blank"
              href={walletLink}
            >
              <ButtonUI
                className={styles.tryButton}
                onClick={() => {tryWallet()}}
                $w="240px"
              >
                Открыть
              </ButtonUI>
            </a>
          </div>

          <div className={styles.background}>
            <StaticImage
              className={styles.backgroundImg}
              src="../../images/elektronniy-koshelek/background.png"
              loading="eager"
              placeholder="none"
              formats={["auto", "webp"]}
              width={576}
              height={523}
              layout="fixed"
              objectFit="contain"
              alt="background-img"
            />
          </div>
        </div>

        <ButtonOfScrollCt id="whyWallet" $w="100%" $mt="120px" />

        <div id="whyWallet" className={styles.containerWhyWallet}>
          <div className={styles.whyWalletContainerImg}>
            <StaticImage
              className={styles.whyWalletImg}
              src="../../images/elektronniy-koshelek/why-wallet.png"
              loading="eager"
              placeholder="none"
              formats={["auto", "webp"]}
              width={678}
              height={495}
              layout="fixed"
              objectFit="contain"
              alt="why-wallet-img"
            />
          </div>
          <div className={styles.whyWalletText}>
            <TypographyUI.H2
              swichPoint={LAPTOP_MOBILE_POINTER}
              className={styles.title}
            >
              Зачем мне Кошелек
            </TypographyUI.H2>
            <TypographyUI.Body1Regular className={styles.description}>
              Допустим, на свой день рождения вы хотите попросить «сколько не жалко» в социальных сетях. Вместо того, чтобы публиковать номер карты, можно поделиться ссылкой на свой личный электронный кошелек
            </TypographyUI.Body1Regular>
            <TypographyUI.Body1Regular className={styles.description}>
              Подписчикам не придется открывать приложение банка и вручную вводить цифры — они сразу попадут на платежную форму и отправят вам деньги.
            </TypographyUI.Body1Regular>
            <TypographyUI.Body1Regular className={styles.description}>
              И это не единственная возможность Кошелька 😉
            </TypographyUI.Body1Regular>
          </div>
        </div>

        <div className={styles.containerAdvantages}>
          <TypographyUI.H2
            swichPoint={LAPTOP_MOBILE_POINTER}
            className={styles.title}
          >
            Какие сервисы есть в Кошельке
          </TypographyUI.H2>

          <div className={styles.advantageItems}>
            <div className={styles.advantageItem}>
              <div className={styles.advantageItemImg}>
                <StaticImage
                  src="../../images/elektronniy-koshelek/advantage-1.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="additional-service-1"
                />
              </div>
              <div className={styles.advantageItemText}>
                <TypographyUI.H3 className={styles.advantageItemTitle}>
                  Ссылка-визитка
                </TypographyUI.H3>
                <TypographyUI.Body1Regular
                  className={styles.advantageItemDescription}
                >
                  При регистрации каждому пользователю присваивается уникальная ссылка-визитка — разместите ее в социальных сетях или на сайте для приема донатов
                </TypographyUI.Body1Regular>
              </div>
            </div>

            <div className={styles.advantageItem}>
              <div className={styles.advantageItemImg}>
                <StaticImage
                  src="../../images/elektronniy-koshelek/advantage-6.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="additional-service-6"
                />
              </div>
              <div className={styles.advantageItemText}>
                <TypographyUI.H3 className={styles.advantageItemTitle}>
                  Запрос денег
                </TypographyUI.H3>
                <TypographyUI.Body1Regular
                  className={styles.advantageItemDescription}
                >
                  Создайте новую ссылку с просьбой перевести конкретную сумму денег и отправьте ее другу или родственнику
                </TypographyUI.Body1Regular>
              </div>
            </div>

            <div className={styles.advantageItem}>
              <div className={styles.advantageItemImg}>
                <StaticImage
                  src="../../images/elektronniy-koshelek/advantage-2.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="additional-service-2"
                />
              </div>
              <div className={styles.advantageItemText}>
                <TypographyUI.H3 className={styles.advantageItemTitle}>
                  Копилка
                </TypographyUI.H3>
                <TypographyUI.Body1Regular
                  className={styles.advantageItemDescription}
                >
                  Откройте сбор средств на общую цель. Например, копите на совместное путешествие, подарок общему другу или корпоратив
                </TypographyUI.Body1Regular>
              </div>
            </div>

            <div className={styles.advantageItem}>
              <div className={styles.advantageItemImg}>
                <StaticImage
                  src="../../images/elektronniy-koshelek/advantage-3.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="additional-service-3"
                />
              </div>
              <div className={styles.advantageItemText}>
                <TypographyUI.H3 className={styles.advantageItemTitle}>
                  Эквайринг
                </TypographyUI.H3>
                <TypographyUI.Body1Regular
                  className={styles.advantageItemDescription}
                >
                  Настройте прием средств для некоммерческих целей на своем сайте
                </TypographyUI.Body1Regular>
              </div>
            </div>

          </div>
        </div>

        <div className={styles.containerServices}>
          <TypographyUI.H2
            swichPoint={LAPTOP_MOBILE_POINTER}
            className={styles.serviceTitle}
          >
            Преимущества Кошелька
          </TypographyUI.H2>
          <div className={styles.serviceItems}>
            <div className={styles.serviceItem}>
              <div className={styles.serviceItemContainerImg}>
                <img
                  className={styles.serviceItemImg}
                  alt="service-wallet"
                  src={sWalletImg}
                />
              </div>
              <div>
                <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                  Моментальный прием и вывод денег
                </TypographyUI.H4Bold>
                <TypographyUI.Body1Regular
                  className={styles.serviceItemDescription}
                >
                  Получайте деньги по ссылке, QR-коду или через онлайн-эквайринг и выводите средства на карту или другие электронные кошельки в России
                </TypographyUI.Body1Regular>
              </div>
            </div>

            <div className={styles.serviceItem}>
              <div className={styles.serviceItemContainerImg}>
                <img
                  className={styles.serviceItemImg}
                  alt="service-history"
                  src={sHistoryImg}
                />
              </div>
              <div>
                <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                  Понятная история операций
                </TypographyUI.H4Bold>
                <TypographyUI.Body1Regular
                  className={styles.serviceItemDescription}
                >
                  Все пополнения баланса электронного кошелька и вывод средств хранятся в вашем личном кабинете
                </TypographyUI.Body1Regular>
              </div>
            </div>

            <div className={styles.serviceItem}>
              <div className={styles.serviceItemContainerImg}>
                <img
                  className={styles.serviceItemImg}
                  alt="service-free"
                  src={sFreeImg}
                />
              </div>
              <div>
                <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                  Бесплатные сервисы
                </TypographyUI.H4Bold>
                <TypographyUI.Body1Regular
                  className={styles.serviceItemDescription}
                >
                  Вы платите только комиссию в размере {commissionPercent}% за вывод денежных средств
                  <InfoTooltip id={'commission-tooltip'}>
                    <TypographyUI.CaptionRegular $c={'var(--text-contrast)'}>Минимальный размер комиссии {commissionMinimal} ₽</TypographyUI.CaptionRegular>
                  </InfoTooltip>
                </TypographyUI.Body1Regular>
              </div>
            </div>

            <div className={styles.serviceItem}>
              <div className={styles.serviceItemContainerImg}>
                <img
                  className={styles.serviceItemImg}
                  alt="service-card"
                  src={sCardImg}
                />
              </div>
              <div>
                <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                  Платежи в Телеграм
                </TypographyUI.H4Bold>
                <TypographyUI.Body1Regular
                  className={styles.serviceItemDescription}
                >
                  Вы также можете настроить автоматический прием денег с помощью Телеграм-бота
                </TypographyUI.Body1Regular>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.containerSoonCard}>
          <div className={styles.soonCardText}>
            <TypographyUI.H2
              swichPoint={LAPTOP_MOBILE_POINTER}
              className={styles.title}
            >
              Скоро — платежные карты
            </TypographyUI.H2>
            <TypographyUI.Body1Regular className={styles.description}>
              С платежной картой Сам.Кард вы забудете о переводах и комиссиях — приложите пластиковую или электронную карту к терминалу и оплатите покупку балансом Кошелька
            </TypographyUI.Body1Regular>
          </div>
          <div>
            <StaticImage
              src="../../images/elektronniy-koshelek/soon-card.png"
              loading="eager"
              placeholder="none"
              formats={["auto", "webp"]}
              layout="constrained"
              objectFit="contain"
              alt="soon-card"
            />
          </div>
        </div>

        <div className={styles.containerBanner}>
          <div className={styles.bannerText}>
            <TypographyUI.H2
              swichPoint={LAPTOP_MOBILE_POINTER}
              className={styles.bannerTitle}
            >
              Создайте Кошелек и начните работать онлайн
            </TypographyUI.H2>
            <TypographyUI.Body1Regular className={styles.bannerDescription}>
              Скачайте кошелек и получайте деньги онлайн. Переводите деньги друзьям,   Ваши клиенты смогут оплатить товары и услуги любым удобным способом
            </TypographyUI.Body1Regular>
            <div className={styles.bannerDownload}>
              <a
                className={styles.bannerDownloadLink}
                target="_blank"
                href={walletStoreLinkIos}
                onClick={clickDownloadApple}
              >
                <StaticImage
                  className={styles.bannerDownloadAppStore}
                  src="../../images/shared/app-store.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="app-store"
                />
              </a>
              <a
                className={styles.bannerDownloadLink}
                target="_blank"
                href={walletStoreLinkMd}
                onClick={clickDownloadGoogle}
              >
                <StaticImage
                  className={styles.bannerDownloadGoogleStore}
                  src="../../images/shared/google-store.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="app-store"
                />
              </a>
            </div>
          </div>

          <div className={styles.bannerImg}>
            <StaticImage
              src="../../images/elektronniy-koshelek/banner.png"
              loading="eager"
              placeholder="none"
              layout="constrained"
              objectFit="contain"
              alt="banner"
            />
          </div>
        </div>

        <div className={styles.containerOpenWallet}>
          <TypographyUI.H1
            swichPoint={LAPTOP_MOBILE_POINTER}
            className={styles.openWalletTitle}
            variant="h2"
          >
            Мгновенное открытие кошелька
          </TypographyUI.H1>
          <div className={styles.openWalletSteps}>
            <div className={styles.openWalletStepItem}>
              <img
                className={styles.openWalletStepItemNum}
                src={srcNumStep1Img}
                alt="num-1"
              />
              <TypographyUI.H4Bold className={styles.openWalletStepItemTitle}>
                Пройдите регистрацию
              </TypographyUI.H4Bold>
              <TypographyUI.Body1Regular
                className={styles.openWalletStepItemDescription}
              >
                Так вы создадите неавторизованную учетную запись
              </TypographyUI.Body1Regular>
            </div>
            <div className={styles.openWalletStepItem}>
              <img
                className={styles.openWalletStepItemNum}
                src={srcNumStep2Img}
                alt="num-2"
              />
              <TypographyUI.H4Bold className={styles.openWalletStepItemTitle}>
                Пройдите идентификацию
              </TypographyUI.H4Bold>
              <TypographyUI.Body1Regular
                className={styles.openWalletStepItemDescription}
              >
                Укажите паспортные данные для официального приема и вывода средств
              </TypographyUI.Body1Regular>
            </div>
            <div className={styles.openWalletStepItem}>
              <a target="_blank" href={walletLink}>
                <ButtonUI
                    className={styles.createWalletBtn}
                    onClick={() => {openWallet()}}
                    $w="240px"
                >
                  Открыть кошелек
                </ButtonUI>
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default WalletFlPage;
