// extracted by mini-css-extract-plugin
export var advantageItem = "style-module--advantage-item--df8ab";
export var advantageItemBtn = "style-module--advantage-item-btn--0f6e3";
export var advantageItemDescription = "style-module--advantage-item-description--154db";
export var advantageItemHeader = "style-module--advantage-item-header--3d39d";
export var advantageItemImg = "style-module--advantage-item-img--f8973";
export var advantageItemText = "style-module--advantage-item-text--f67f1";
export var advantageItemTitle = "style-module--advantage-item-title--4a95e";
export var advantageItems = "style-module--advantage-items--972ae";
export var background = "style-module--background--5d972";
export var backgroundImg = "style-module--background-img--db9d3";
export var bannerDescription = "style-module--banner-description--10988";
export var bannerDownload = "style-module--banner-download--5dc26";
export var bannerDownloadAppStore = "style-module--banner-download-app-store--a6c4e";
export var bannerDownloadGoogleStore = "style-module--banner-download-google-store--c4f6f";
export var bannerDownloadLink = "style-module--banner-download-link--b4972";
export var bannerImg = "style-module--banner-img--16ecb";
export var bannerText = "style-module--banner-text--32bd4";
export var bannerTitle = "style-module--banner-title--034cb";
export var containerAdvantages = "style-module--container-advantages--9788b";
export var containerBanner = "style-module--container-banner--27115";
export var containerOpenWallet = "style-module--container-open-wallet--52e28";
export var containerServices = "style-module--container-services--4ac82";
export var containerSoonCard = "style-module--container-soon-card--8ad30";
export var containerTop = "style-module--container-top--33433";
export var containerWhyWallet = "style-module--container-why-wallet--f5c21";
export var createWalletBtn = "style-module--create-wallet-btn--781e4";
export var description = "style-module--description--40d6f";
export var openWalletStepItem = "style-module--open-wallet-step-item--ed3a8";
export var openWalletStepItemDescription = "style-module--open-wallet-step-item-description--cc07b";
export var openWalletStepItemNum = "style-module--open-wallet-step-item-num--1b015";
export var openWalletStepItemTitle = "style-module--open-wallet-step-item-title--cfe6b";
export var openWalletSteps = "style-module--open-wallet-steps--6ff34";
export var openWalletTitle = "style-module--open-wallet-title--e7116";
export var serviceItem = "style-module--service-item--ed664";
export var serviceItemContainerImg = "style-module--service-item-container-img--5b886";
export var serviceItemDescription = "style-module--service-item-description--8037e";
export var serviceItemImg = "style-module--service-item-img--fa36a";
export var serviceItemLink = "style-module--service-item-link--f50ee";
export var serviceItemTitle = "style-module--service-item-title--b325d";
export var serviceItems = "style-module--service-items--d66dc";
export var serviceTitle = "style-module--service-title--fe2a9";
export var soonCardText = "style-module--soon-card-text--43d5d";
export var text = "style-module--text--37da8";
export var title = "style-module--title--332a6";
export var tryButton = "style-module--try-button--51623";
export var whyWalletContainerImg = "style-module--why-wallet-container-img--d205b";
export var whyWalletImg = "style-module--why-wallet-img--89492";
export var whyWalletText = "style-module--why-wallet-text--7c5c3";